<template>
  <div>
    <v-sheet
      class="pa-8">
      <v-row v-if="stockCounterData">
        <v-col
          cols="12">
          <div class="d-flex align-center">
            <v-icon @click="goBack">
              mdi-arrow-left
            </v-icon>
            <p class="text-h6 mb-0 ml-3">
              รอบการนับ {{ stockCounterData.counterCode || '-' }} จำนวน ({{ totalFound | showNumberFormat }})
            </p>
          </div>
        </v-col>
        <v-col
          cols="12">
          <p>
            <span class="font-weight-bold mr-2">Code : </span> <span class="mr-2">{{ stockCounterData.stockCount.code || '-' }}</span>
            <span class="font-weight-bold mr-2">Remark / Location : </span> <span class="mr-2">{{ stockCounterData.remark || '-' }}</span>
          </p>
          <p>
            <span class="font-weight-bold mr-2">เวลาที่บันทึก : </span>
            <span class="mr-2">{{ stockCounterData.createdAt | dateTimeUserFormat }}</span>
            <span class="font-weight-bold mr-2">บันทึกโดย : </span>
            <span>{{ stockCounterData.createdBy.email || '-' }}</span>
          </p>
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="4"
          xl="3">
          <text-field-form
            v-model="searchQuery"
            outlined
            name="Search"
            out-field-label="ค้นหา"
            placeholder="ค้นหาด้วยชื่อสินค้า" />
        </v-col>
        <v-col
          v-if="loadingProduct"
          class="text-center"
          cols="12">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
        <v-col
          v-else
          cols="12">
          <v-card
            v-for="(product, index) in products"
            :key="index"
            class="mb-4 pa-4">
            <v-simple-table>
              <tbody>
                <tr>
                  <td width="25%">
                    <img
                      width="60px"
                      :src="product.photoUrl"
                      alt="">
                  </td>
                  <td colspan="2">
                    <p>{{ product.name }}</p> <p>{{ product.model }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    Code
                  </td>
                  <td
                    width="30%"
                    class="font-weight-bold">
                    สี - ไซส์
                  </td>
                  <td class="font-weight-bold">
                    นับได้
                  </td>
                </tr>
                <tr
                  v-for="(sku, sindex) in product.skus"
                  :key="`sku-${sindex}`">
                  <td>
                    {{ sku.code }}
                  </td>
                  <td> {{ sku.color }} - {{ sku.size }}</td>
                  <td> {{ sku.count }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-col
        v-else
        class="text-center"
        cols="12">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-sheet>
  </div>
</template>

<script>
import StockCounterProvider from '@/resources/StockCounterProvider'
import ProductProvider from '@/resources/ProductProvider'

export default {
  components: {
  },
  data () {
    return {
      stockCounterProvider: new StockCounterProvider(),
      productProvider: new ProductProvider(),
      stockCounterData: null,
      countingResult: null,
      loadingCountingResult: false,
      loadingProduct: false,
      searchQuery: '',
      status: [
        { value: '', label: 'ทั้งหมด', color: '' },
        { value: 'ready', label: 'พร้อมนับ', color: 'success' },
        { value: 'waiting', label: 'รอตรวจสอบ', color: 'indigo' },
        { value: 'completed', label: 'เสร็จสิ้น', color: 'grey' }
      ],
      headers: [
        {
          text: 'Select',
          align: 'left',
          value: 'select',
          sortable: false,
          width: '30'
        },
        {
          text: 'Remark / Location',
          align: 'left',
          value: 'remark',
          sortable: false
        },
        {
          text: 'Commit Time',
          align: 'center',
          value: 'createdAt',
          sortable: false
        },
        {
          text: 'Commit by',
          align: 'center',
          value: 'createdBy.email',
          sortable: false
        },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ]
    }
  },
  computed: {
    totalFound () {
      return this.countingResult?.totalFound ?? 0
    },
    products () {
      return this.countingResult.products.filter((p) => {
        if (!this.searchQuery) return true
        return (p.name && p.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
               || (p.skus && p.skus.find((sku) => sku.code.toLowerCase().includes(this.searchQuery.toLowerCase())))
      })
    }
  },
  async mounted () {
    this.fetchByStockCounterById(this.$route.params.counterId)
  },
  methods: {
    async fetchByStockCounterById (id) {
      this.loadingCountingResult = true
      try {
        const { data } = await this.stockCounterProvider.getByStockCounterId(id)
        this.stockCounterData = data
        this.fetchProductByAliasId()
      } catch (error) {
        console.error('fetchByStockCounterById', error)
      } finally {
        this.loadingCountingResult = false
      }
    },
    groupingAndCountTags (listOfTags) {
      const groupedTags = {}
      for (const tag of listOfTags) {
        const tagKey = `${tag}`
        if (groupedTags[tagKey]) {
          groupedTags[tagKey] += 1
        } else {
          groupedTags[tagKey] = 1
        }
      }
      return groupedTags
    },
    async fetchProductByAliasId () {
      this.loadingProduct = true
      try {
        const aliasIds = this.groupingAndCountTags(Object.values(this.stockCounterData.tags))
        const { data } = await this.productProvider.fetchProductsByAliasIds({ countingAliasIds: aliasIds })
        this.countingResult = data
      } catch (error) {
        console.error('fetchProductByAliasId', error)
      } finally {
        this.loadingProduct = false
      }
    },
    goBack () {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'StockCountPreview', params: { id: this.$route.params.stockCountId } })
      }
    }
  }
}
</script>
